@use '@angular/material' as mat;
mat-sidenav {
  padding: 0 6px 0 2px;

  .mat-list-base[dense] {
    .mat-list-item {
      height: 50px;

      &.logo {
        padding-top: 9px;
        padding-left: 6px;
        box-sizing: border-box;
        margin-bottom: 20px;

        img {
          height: 48px;
        }
      }

      .mat-list-item-content {
        padding: 0 6px;
        display: flex;

        button {
          padding: 0 12px;
          flex: 1 1 auto;
          text-align: left;
          text-transform: none;
          font-size: 16px;
          font-weight: 600;
          height: 50px;
          line-height: 28px;

          app-page-title {
            display: inline-block;
            white-space: nowrap;
          }

          &:not(.mat-accent) {
            &:hover {
              background-color: rgba(mat.m2-get-color-from-palette($md-digitaldarkblue, 500), 0.35);
            }
          }

          &.selected {
            background-color: mat.m2-get-color-from-palette($md-digitaldarkblue, 700) !important;
          }

          .mat-icon {
            @include icon-size(28px);
            margin-right: 12px !important;
          }
        }
      }
    }
  }
  ph-flex-sidenav-menu-item {
    outline: 0;
  }
}

mat-sidenav.mat-drawer {
  background: mat.m2-get-color-from-palette($md-primary, 500);
}
