@use 'flex-app-shared/styles/mixins';

/* You can add global styles to this file, and also import other style files */
// Globals
@import 'normalize.css';
@import '@angular/cdk/overlay-prebuilt';

// Material
@import 'flex-app-shared/styles/palette';
@import 'flex-app-shared/styles/mixins';
@import 'flex-app-shared/styles/typography';
@import 'flex-app-shared/styles/theming';
@import 'flex-app-shared/styles/settings';
@include init-typography();

// Components
@import 'flex-app-shared/styles/buttons';
@import 'flex-app-shared/styles/calendars';
@import 'flex-app-shared/styles/cards';
@import 'flex-app-shared/styles/chips';
@import 'flex-app-shared/styles/dialogs';
@import 'flex-app-shared/styles/forms';
@import 'flex-app-shared/styles/icons';
@import 'flex-app-shared/styles/layout';
@import 'flex-app-shared/styles/legends';
@import 'flex-app-shared/styles/links';
@import 'flex-app-shared/styles/lists';
@import 'flex-app-shared/styles/menus';
@import 'flex-app-shared/styles/paginator';
@import 'flex-app-shared/styles/sidenav-cust';
@import 'flex-app-shared/styles/states';
@import 'flex-app-shared/styles/stepper';
@import 'flex-app-shared/styles/tables';
@import 'flex-app-shared/styles/tabs';
@import 'flex-app-shared/styles/toolbars';
@import 'flex-app-shared/styles/tooltips-hints';
@import 'flex-app-shared/styles/utils';

// new styling

@import 'flex-app-shared/src/lib/core/styles/components/containers';
@import 'flex-app-shared/src/lib/core/styles/components/cards';
@import 'flex-app-shared/src/lib/core/styles/components/buttons';
@import 'flex-app-shared/src/lib/core/styles/components/form-field';

@include mixins.formHeight();

.mat-icon[svgicon] svg {
  //shape-rendering: crispEdges;
}

:root {
  --flex-icon-button-icon-size: 20px;

  // Only apply to customer portal
  --mat-form-field-container-text-line-height: 30px;
}
